/* eslint quotes: [0, "double"] */
const Countries = [
  { code: "Canada", name: "Canada", abbr: "CA", flag: "🇨🇦", currency: "CAD" },
  { code: "United States", name: "United States", abbr: "US", flag: "🇺🇸", currency: "USD" },
]

// {CA: 'Canada', US: 'United States'}
const CountryMap = {}
// {CA: '🇨🇦', US: '🇺🇸'}
const CountryFlagMap = {}
// {CA: 'CAD', US: 'USD'}
const CountryCurrencyMap = {}

Countries.forEach((country) => {
  CountryMap[country.abbr] = country.code
  CountryFlagMap[country.abbr] = country.flag
  CountryCurrencyMap[country.abbr] = country.currency
})
export { CountryMap, CountryFlagMap, CountryCurrencyMap }

export default Countries
