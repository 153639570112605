<template>
  <ValidationObserver ref="step1Form" v-slot="{ valid }">
    <div class="box plain-shadow-on-desktop">
      <div class="page-title has-text-centered">Residential Address</div>
      <div class="page-subtitle has-text-centered">
        <div>This address will be used to fill out</div>
        <div>your official shareholder documents.</div>
      </div>
      <AddressForm ref="residentialForm" step="residential" :investor="investor"></AddressForm>
      <div class="checkbox-field">
        <b-checkbox v-model="mailingAddressIsSame" class="mailing-is-same" type="is-blue">My mailing address is the same as my residential address</b-checkbox>
      </div>
    </div>
    <BottomButtons :step="2" :nextDisabled="!valid" :loading="nextIsLoading" @goNext="goNext" @goBack="goBack"></BottomButtons>
  </ValidationObserver>
</template>
<script>
import AddressForm from './AddressForm.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { updateInvestorProfile } from '@api/signup'
import { mapState } from 'vuex'

export default {
  components: {
    AddressForm,
    BottomButtons,
  },
  data() {
    return {
      mailingAddressIsSame: false,
      nextIsLoading: false,
    }
  },
  props: {
    investor: {
      type: Object,
      required: true
    },
  },
  mounted() {
    if (this.$route.query.mailingIsSame) this.mailingAddressIsSame = true
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
  },
  methods: {
    goNext() {
      this.nextIsLoading = true
      this.updateAddress(this.mailingAddressIsSame)
    },
    goBack() {
      this.$router.push('/signup/profile/5?noRedirect=true')
    },
    updateAddress(both) {
      const { unitNumber, streetNumber, locality, postalCode, country, administrativeAreaLevel1 } = this.$refs.residentialForm
      const payload = {
        locality,
        unit_number: unitNumber,
        street_number: streetNumber,
        postal_code: postalCode,
      }
      if (both) {
        const mailingPayload = {
          mailing_country: country,
          mailing_administrative_area_level_1: administrativeAreaLevel1,
          mailing_unit_number: unitNumber,
          mailing_street_number: streetNumber,
          mailing_locality: locality,
          mailing_postal_code: postalCode,
          signup_status: 'complete',
        }
        Object.assign(payload, mailingPayload)
      }

      updateInvestorProfile(payload).then((data) => {
        localStorage.setItem('investor', JSON.stringify(data))
        this.nextIsLoading = false
        if (both) {
          const isCanadian = country === 'Canada'
          const tracker = isCanadian ? 'ivnintij' : '4hfwgxdw'
          const route = isCanadian ? '/signup/wallet' : '/signup/questionnaire'
          this.mixpanel.track(tracker)
          this.$router.push(route)
        } else {
          this.mixpanel.track('8ke5m9vm')
          this.$router.push('/signup/address/2')
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.box {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 26px;
  padding-bottom: 26px;
  box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
  .page-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
    letter-spacing: 0.03em;
  }
  .page-subtitle {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 22px;
  }
  .checkbox-field {
    margin-top: 42px;
    font-size: 16px;
    padding: 0 16px;
  }
}
</style>
<style scoped>
.checkbox.mailing-is-same >>> .check {
  width: 22px!important;
  height: 22px!important;
}
</style>
