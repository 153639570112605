<template>
  <ValidationObserver ref="step2Form" v-slot="{ valid }">
    <div class="box plain-shadow-on-desktop">
      <div class="page-title has-text-centered">Mailing Address</div>
      <div class="page-subtitle has-text-centered">
        <div>What would you like to be your mailing</div>
        <div>address?</div>
      </div>
      <AddressForm ref="mailingForm" step="mailing" :investor="investor"></AddressForm>
    </div>
    <BottomButtons :step="2" :nextDisabled="!valid" :loading="nextIsLoading" @goNext="goNext" @goBack="goBack"></BottomButtons>
  </ValidationObserver>
</template>
<script>
import AddressForm from './AddressForm.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { updateInvestorProfile } from '@api/signup'
import { mapState } from 'vuex'

export default {
  components: {
    AddressForm,
    BottomButtons,
  },
  data() {
    return {
      nextIsLoading: false,
    }
  },
  props: {
    investor: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
  },
  methods: {
    goNext() {
      this.nextIsLoading = true
      const { unitNumber, streetNumber, locality, postalCode, country, administrativeAreaLevel1 } = this.$refs.mailingForm
      const payload = {
        mailing_country: country,
        mailing_administrative_area_level_1: administrativeAreaLevel1,
        mailing_unit_number: unitNumber,
        mailing_street_number: streetNumber,
        mailing_locality: locality,
        mailing_postal_code: postalCode,
        signup_status: 'complete',
      }
      updateInvestorProfile(payload).then((data) => {
        localStorage.setItem('investor', JSON.stringify(data))
        this.nextIsLoading = false
        const isCanadian = country === 'Canada'
        const tracker = isCanadian ? 'ivnintij' : '4hfwgxdw'
        const route = isCanadian ? '/signup/wallet' : '/signup/questionnaire'
        this.mixpanel.track(tracker)
        this.$router.push(route)
      })
    },
    goBack() {
      this.$router.push('/signup/address/1')
    },
  },
}
</script>
<style lang="scss" scoped>
.box {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 26px;
  padding-bottom: 26px;
  box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
  .page-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
    letter-spacing: 0.03em;
  }
  .page-subtitle {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 22px;
  }
}
</style>
