<template>
  <div class="address-form">
    <div class="step-title">Address</div>
    <ValidationProvider name="streetNumber" rules="required" v-slot="{ errors, passed, failed }">
      <div class="field street-number-field">
        <div class="control has-icons-right">
          <input :class="`input is-medium-height ${classForField({ passed, failed })}`" type="text" placeholder="Street address" v-model.trim="streetNumber">
          <span class="icon is-small is-right">
            <img
              v-show="failed"
              class="icon-exclamation"
              src="@assets/icons/exclamation.svg"
              alt="is-danger">
            <img
              v-show="passed"
              class="icon-check"
              src="@assets/icons/check.svg"
              alt="is-success">
          </span>
        </div>
        <p class="help is-danger" v-show="failed">{{errors[0]}}</p>
      </div>
    </ValidationProvider>
    <div class="field unit-number-field">
      <div class="control">
        <input class="input is-medium-height" type="text" placeholder="Unit number (optional)" v-model.trim="unitNumber">
      </div>
    </div>
    <ValidationProvider name="locality" rules="required" v-slot="{ errors, passed, failed }">
      <div class="field locality-field">
        <div class="control has-icons-right">
          <input :class="`input is-medium-height ${classForField({ passed, failed })}`" type="text" placeholder="City" v-model.trim="locality">
          <span class="icon is-small is-right">
            <img
              v-show="failed"
              class="icon-exclamation"
              src="@assets/icons/exclamation.svg"
              alt="is-danger">
            <img
              v-show="passed"
              class="icon-check"
              src="@assets/icons/check.svg"
              alt="is-success">
          </span>
        </div>
        <p class="help is-danger" v-show="failed">{{errors[0]}}</p>
      </div>
    </ValidationProvider>
    <ValidationProvider name="postalCode" rules="required" v-slot="{ errors, passed, failed }">
      <div class="field postal-code-field">
        <div class="control has-icons-right">
          <input
            :class="`input is-medium-height ${classForField({ passed, failed })}`"
            type="text"
            :placeholder="isUsa ? 'Zip Code' : 'Postal Code'"
            v-model.trim="postalCode"
          >
          <span class="icon is-small is-right">
            <img
              v-show="failed"
              class="icon-exclamation"
              src="@assets/icons/exclamation.svg"
              alt="is-danger">
            <img
              v-show="passed"
              class="icon-check"
              src="@assets/icons/check.svg"
              alt="is-success">
          </span>
        </div>
        <p class="help is-danger" v-show="failed">{{errors[0]}}</p>
      </div>
    </ValidationProvider>
    <div class="is-flex is-justify-content-space-between country-and-province">
      <ValidationProvider name="country" rules="required" v-slot="{ errors, passed, failed }">
        <b-field :type="classForField({ passed,failed })" :message="errors[0]">
          <b-select name="country" placeholder="Country" v-model="country" @input="resetStateOrProvince" class="is-medium-height is-blue country" :disabled="step === 'residential'">
            <option
              v-for="option in Countries"
              :value="option.code"
              :key="option.code"
            >{{ option.name }}</option>
          </b-select>
        </b-field>
      </ValidationProvider>
      <ValidationProvider name="administrativeAreaLevel1" rules="required" v-slot="{ errors, passed, failed }">
        <b-field :type="classForField({ passed, failed })" :message="errors[0]">
          <b-select
            name="administrativeAreaLevel1"
            :placeholder="stateOrProvincePlaceholder"
            v-model="administrativeAreaLevel1"
            :key="isUsa ? 'stately-input' : 'province-input'"
            class="is-medium-height is-blue administrativeAreaLevel1"
            :disabled="step === 'residential'"
            expanded
          >
            <option
              v-for="option in (isUsa ? UsaStates : CanadaProvinces)"
              :value="option.code"
              :key="option.code"
            >{{ option.code }}</option>
          </b-select>
        </b-field>
      </ValidationProvider>
    </div>
  </div>
</template>
<script>
import formMixins from '@utils/mixins/form-mixins.js'
import Countries from '@utils/data/countries'
import UsaStates from '@utils/data/usa_states'
import CanadaProvinces from '@utils/data/canada_provinces'

export default {
  mixins: [formMixins],
  data() {
    return {
      unitNumber: null,
      streetNumber: undefined,
      locality: undefined,
      postalCode: undefined,
      country: undefined,
      administrativeAreaLevel1: undefined,
      Countries,
      UsaStates,
      CanadaProvinces,
    }
  },
  props: {
    step: {
      type: String,
      required: true
    },
    investor: {
      type: Object,
      required: true
    },
  },
  computed: {
    isUsa() {
      return this.country && this.country === 'United States'
    },
    stateOrProvincePlaceholder() {
      return this.isUsa ? 'State' : 'Province'
    },
  },
  watch: {
    investor() {
      this.getCountryAndProvince()
    }
  },
  methods: {
    getCountryAndProvince() {
      this.country = this.investor.country
      if (this.step === 'residential') this.administrativeAreaLevel1 = this.investor.administrativeAreaLevel1
    },
    resetStateOrProvince() {
      this.administrativeAreaLevel1 = null
    },
  }
}
</script>
<style lang="scss" scoped>
.address-form {
  .step-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
    padding-left: 10px;
  }
  .field {
    &.unit-number-field,
    &.street-number-field,
    &.locality-field,
    &.postal-code-field {
      margin-bottom: 8px;
    }
  }
  .is-flex .field {
    width: 100%;
  }
  .country-and-province {
    > span {
      display: inline-block;
      width: 50%;
      .country {
        padding-right: 8px;
        ::v-deep > span {
          display: inline-block;
          width: 100%;
          select {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
