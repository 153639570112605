<template>
  <div class="address-wrap">
    <ResidentialAddress ref="residential" v-show="activeStep === '1'" :investor="investor"></ResidentialAddress>
    <MailingAddress ref="mailing" v-show="activeStep === '2'" :investor="investor"></MailingAddress>
  </div>
</template>
<script>
import ResidentialAddress from './ResidentialAddress.vue'
import MailingAddress from '@views/signup/MailingAddress.vue'
import { getInvestorData } from '@api/signup'

export default {
  components: {
    ResidentialAddress,
    MailingAddress,
  },
  data() {
    return {
      investor: {},
    }
  },
  computed: {
    activeStep() {
      return this.$route.params.step
    },
  },
  created() {
    this.getInvestorInfo()
  },
  methods: {
    getInvestorInfo() {
      getInvestorData().then((data) => {
        this.investor = data
        localStorage.setItem('investor', JSON.stringify(data))
        const { unitNumber, streetNumber, locality, postalCode, mailingUnitNumber, mailingStreetNumber, mailingLocality, mailingPostalCode, mailingCountry, mailingAdministrativeAreaLevel1 } = data
        if (streetNumber) {
          this.$refs.residential.$refs.residentialForm.unitNumber = unitNumber
          this.$refs.residential.$refs.residentialForm.streetNumber = streetNumber
          this.$refs.residential.$refs.residentialForm.locality = locality
          this.$refs.residential.$refs.residentialForm.postalCode = postalCode
        }
        if (mailingStreetNumber) {
          this.$refs.mailing.$refs.mailingForm.unitNumber = mailingUnitNumber
          this.$refs.mailing.$refs.mailingForm.streetNumber = mailingStreetNumber
          this.$refs.mailing.$refs.mailingForm.locality = mailingLocality
          this.$refs.mailing.$refs.mailingForm.postalCode = mailingPostalCode
          this.$refs.mailing.$refs.mailingForm.country = mailingCountry
          this.$refs.mailing.$refs.mailingForm.administrativeAreaLevel1 = mailingAdministrativeAreaLevel1
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.address-wrap {
  padding: 0 25px 140px;
}
</style>
